<template>
  <div id="login-partner-container" />
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import secureLs from '@/libs/secureLs'

export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = `${process.env.VUE_APP_BASE_AUTH_MODULE}/login-partner.js`
    script.async = true

    document.head.appendChild(script)

    // receive message from iframe
    return new Promise((resolve, reject) => {
      window.addEventListener(
        'message',
        async event => {
          const {
            type, data, access_token, access_account, route, password, expires,
          } = event.data

          if (route) {
            this.$router.push(route)
          }

          if (type === 'login-complete') {
            this.email = data.email
            this.password = password

            useJwt.setToken(access_token, expires)
            resolve('OK')

            const role = data.role_name.toUpperCase()
            const komship = data.is_komship === 1
            const kompack = data.is_kompack === 1
            const komcards = data.is_komcards === 1
            const komform = data.is_komform === 1
            const komchat = data.is_komchat === 1
            const komads = data.is_komads === 1

            let ability = []

            switch (role) {
              case 'PARTNER':
                if (komship) {
                  ability = [
                    { action: 'manage', subject: 'Komship TalentPool' },
                    { action: 'manage', subject: 'Komship Wishlist' },
                    { action: 'manage', subject: 'PartnerProfile' },
                    { action: 'read', subject: 'Dashboard Komship' },
                    { action: 'manage', subject: 'Customer' },
                    { action: 'manage', subject: 'Info Customer' },
                    { action: 'manage', subject: 'Data Customer' },
                    { action: 'manage', subject: 'Produk' },
                    { action: 'manage', subject: 'Tambah Produk' },
                    { action: 'manage', subject: 'Data Produk' },
                    { action: 'manage', subject: 'Order' },
                    { action: 'manage', subject: 'Tambah Order' },
                    { action: 'manage', subject: 'Data Order' },
                    { action: 'manage', subject: 'Pickup' },
                    { action: 'manage', subject: 'Ajukan Pickup' },
                    { action: 'manage', subject: 'History Pickup' },
                    { action: 'manage', subject: 'Keuangan' },
                    { action: 'manage', subject: 'Penghasilan' },
                    { action: 'manage', subject: 'Saldo' },
                    { action: 'manage', subject: 'Setting Komship' },
                    { action: 'manage', subject: 'Setting Profile' },
                    { action: 'manage', subject: 'Setting Access Account' },
                    { action: 'manage', subject: 'Team' },
                    { action: 'manage', subject: 'Setting Pickup Address' },
                    { action: 'manage', subject: 'Setting Rekening Bank' },
                    { action: 'manage', subject: 'Setting PIN' },
                    { action: 'manage', subject: 'Setting Ekspedisi' },
                    { action: 'manage', subject: 'Hiring' },
                    { action: 'manage', subject: 'Fitur Pendukung' },
                    { action: 'manage', subject: 'MyApps' },
                    { action: 'manage', subject: 'Kendala' },
                    { action: 'manage', subject: 'Inbox' },
                  ]
                  if (kompack) {
                    ability.push({ action: 'manage', subject: 'Gudangku' })
                    ability.push({ action: 'manage', subject: 'Gudang' })
                    ability.push({ action: 'manage', subject: 'Cari Gudang' })
                    ability.push({ action: 'manage', subject: 'Ajukan Inbound' })
                    ability.push({
                      action: 'manage',
                      subject: 'Riwayat Pengajuan',
                    })
                  }
                  if (!kompack) {
                    ability.push({ action: 'manage', subject: 'Gudangku' })
                    ability.push({ action: 'manage', subject: 'Gudang' })
                  }
                  if (komcards) {
                    ability.push({ action: 'manage', subject: 'KartuKomcards' })
                  }
                }

                if (kompack) {
                  // Gudang
                  ability.push({ action: 'manage', subject: 'Gudangku' })
                  ability.push({ action: 'manage', subject: 'Gudang' })
                  ability.push({ action: 'manage', subject: 'Cari Gudang' })
                  ability.push({ action: 'manage', subject: 'Ajukan Inbound' })
                  ability.push({ action: 'manage', subject: 'Riwayat Pengajuan' })
                  // Produk
                  ability.push({ action: 'manage', subject: 'Produk' })
                  ability.push({ action: 'manage', subject: 'Tambah Produk' })
                  ability.push({ action: 'manage', subject: 'Data Produk' })
                  // Pickup
                  ability.push({ action: 'manage', subject: 'Pickup' })
                  ability.push({ action: 'manage', subject: 'Ajukan Pickup' })
                  ability.push({ action: 'manage', subject: 'History Pickup' })
                  // Keuangan
                  ability.push({ action: 'manage', subject: 'Keuangan' })
                  ability.push({ action: 'manage', subject: 'Saldo' })
                  // Pengaturan
                  ability.push({ action: 'manage', subject: 'Setting Komship' })
                  ability.push({ action: 'manage', subject: 'Setting Profile' })
                  ability.push({ action: 'manage', subject: 'Setting Access Account' })
                  ability.push({ action: 'manage', subject: 'Team' })
                  ability.push({ action: 'manage', subject: 'Setting Rekening Bank' })
                  ability.push({ action: 'manage', subject: 'Setting PIN' })
                  // Aplikasiku
                  ability.push({ action: 'manage', subject: 'MyApps' })
                }

                if (komcards) {
                  ability.push({ action: 'read', subject: 'Dashboard Komship' })
                  ability.push({ action: 'manage', subject: 'Keuangan' })
                  ability.push({ action: 'manage', subject: 'Setting Komship' })
                  ability.push({ action: 'manage', subject: 'Setting Profile' })
                  ability.push({ action: 'manage', subject: 'Setting Pickup Address' })
                  ability.push({ action: 'manage', subject: 'Setting Rekening Bank' })
                  ability.push({ action: 'manage', subject: 'Setting PIN' })
                  ability.push({ action: 'manage', subject: 'MyApps' })
                  ability.push({ action: 'manage', subject: 'KartuKomcards' })
                  ability.push({ action: 'manage', subject: 'Team' })
                }

                if (komform) {
                  ability.push({ action: 'read', subject: 'Dashboard Komship' })
                  ability.push({ action: 'manage', subject: 'Setting Komship' })
                  ability.push({ action: 'manage', subject: 'Setting Profile' })
                  ability.push({ action: 'manage', subject: 'Setting Rekening Bank' })
                  ability.push({ action: 'manage', subject: 'Setting PIN' })
                  ability.push({ action: 'manage', subject: 'Keuangan' })
                  ability.push({ action: 'manage', subject: 'Saldo' })
                  ability.push({ action: 'manage', subject: 'MyApps' })
                  ability.push({ action: 'manage', subject: 'Order' })
                  ability.push({ action: 'manage', subject: 'Form Order' })
                  ability.push({ action: 'manage', subject: 'Inbox' })
                  await this.loginKomform().then(() => {
                    this.$router.go('/')
                  })
                }

                if (komchat) {
                  ability.push({ action: 'manage', subject: 'Fitur Pendukung' })
                  ability.push({ action: 'manage', subject: 'Group Contact' })
                  ability.push({ action: 'manage', subject: 'Broadcast' })
                  ability.push({ action: 'manage', subject: 'Customer' })
                  ability.push({ action: 'manage', subject: 'Setting Profile' })
                  ability.push({ action: 'manage', subject: 'Setting Komship' })
                  ability.push({ action: 'manage', subject: 'MyApps' })
                  ability.push({ action: 'manage', subject: 'Keuangan' })
                  ability.push({ action: 'manage', subject: 'Saldo' })
                }

                // KOMADS
                if (komads) {
                  ability.push({ action: 'read', subject: 'Dashboard Komship' })
                  ability.push({ action: 'manage', subject: 'Marketing' })
                  ability.push({ action: 'manage', subject: 'Saldo' })
                  ability.push({ action: 'manage', subject: 'Keuangan' })
                  ability.push({ action: 'manage', subject: 'Setting Komship' })
                  ability.push({ action: 'manage', subject: 'Setting Profile' })
                  ability.push({ action: 'manage', subject: 'Setting Rekening Bank' })
                  ability.push({ action: 'manage', subject: 'Setting PIN' })
                  ability.push({ action: 'manage', subject: 'MyApps' })
                }
                break
              default:
                break
            }
            if (role === 'TALENT GLOBAL') {
              const menuAccess = access_account.filter(
                item => item.access !== 'No Data Access.',
              )
              menuAccess.forEach(item => {
                if (item.menu_name === 'PRODUCT') {
                  ability.push({ action: 'manage', subject: 'Produk' })
                  const findAccessView = item.access.find(
                    accessItem => accessItem.access_id === 1,
                  )
                  const findAccessManage = item.access.find(
                    accessItem => accessItem.access_id === 2,
                  )
                  if (findAccessView !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Data Produk',
                      path: '/produk',
                    })
                  }
                  if (findAccessManage !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Tambah Produk',
                      path: '/add-produk',
                    })
                  }
                }
                if (item.menu_name === 'ORDER') {
                  ability.push({ action: 'manage', subject: 'Order' })
                  const findAccessView = item.access.find(
                    accessItem => accessItem.access_id === 1,
                  )
                  const findAccessManage = item.access.find(
                    accessItem => accessItem.access_id === 2,
                  )
                  if (findAccessView !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Data Order',
                      path: '/data-order',
                    })
                  }
                  if (findAccessManage !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Tambah Order',
                      path: '/add-order',
                    })
                  }
                }
                if (item.menu_name === 'DASHBOARD') {
                  const findAccessView = item.access.find(
                    accessItem => accessItem.access_id === 1,
                  )
                  if (findAccessView !== undefined) {
                    ability.push({
                      action: 'read',
                      subject: 'Dashboard Komship',
                      path: '/',
                    })
                  }
                }
                if (item.menu_name === 'GUDANG') {
                  // const findAccessView = item.access.find(accessItem => accessItem.access_id === 1)
                  const findAccessCariGudang = item.access.find(
                    accessItem => accessItem.access_id === 18,
                  )
                  const findAccessGudangku = item.access.find(
                    accessItem => accessItem.access_id === 16,
                  )
                  const findAccessAjukanInbound = item.access.find(
                    accessItem => accessItem.access_id === 17,
                  )
                  const findAccessRiwayatPengajuan = item.access.find(
                    accessItem => accessItem.access_id === 19,
                  )
                  ability.push({ action: 'manage', subject: 'Gudang' })
                  if (findAccessGudangku !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Gudangku',
                      path: '/gudangku',
                    })
                  }
                  if (findAccessCariGudang !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Cari Gudang',
                      path: '/cari-gudang',
                    })
                  }
                  if (findAccessAjukanInbound !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Ajukan Inbound',
                      path: '/ajukan-inbound',
                    })
                  }
                  if (findAccessRiwayatPengajuan !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Riwayat Pengajuan',
                      path: '/riwayat-pengajuan',
                    })
                  }
                }
                if (item.menu_name === 'PICKUP') {
                  ability.push({ action: 'manage', subject: 'Pickup' })
                  const findAccessView = item.access.find(
                    accessItem => accessItem.access_id === 6,
                  )
                  const findAccessManage = item.access.find(
                    accessItem => accessItem.access_id === 5,
                  )
                  if (findAccessView !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'History Pickup',
                      path: '/history-pickup',
                    })
                  }
                  if (findAccessManage !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Ajukan Pickup',
                      path: '/ajukan-pickup',
                    })
                  }
                }
                if (item.menu_name === 'FINANCE') {
                  ability.push({ action: 'manage', subject: 'Keuangan' })
                  const findAccessView = item.access.find(
                    accessItem => accessItem.access_id === 7,
                  )
                  const findAccessManage = item.access.find(
                    accessItem => accessItem.access_id === 8,
                  )
                  if (findAccessView !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Penghasilan',
                      path: '/keuangan/penghasilan',
                    })
                  }
                  if (findAccessManage !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Saldo',
                      path: '/keuangan/saldo',
                    })
                  }
                }
                if (item.menu_name === 'SETTING') {
                  ability.push({ action: 'manage', subject: 'Setting Komship' })
                  const findAccessBank = item.access.find(
                    accessItem => accessItem.access_id === 12,
                  )
                  const findAccessPin = item.access.find(
                    accessItem => accessItem.access_id === 13,
                  )
                  const findAccessExpedition = item.access.find(
                    accessItem => accessItem.access_id === 14,
                  )
                  if (findAccessBank !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Setting Rekening Bank',
                      path: '/setting-kompship/rekening-bank',
                    })
                  }
                  if (findAccessPin !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Setting PIN',
                      path: '/setting-kompship/pin',
                    })
                  }
                  if (findAccessExpedition !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Setting Ekspedisi',
                      path: '/setting-kompship/ekspedisi',
                    })
                  }
                }
                if (item.menu_name === 'KENDALA') {
                  const findAccessView = item.access.find(
                    accessItem => accessItem.access_id === 1,
                  )
                  if (findAccessView !== undefined) {
                    ability.push({
                      action: 'manage',
                      subject: 'Kendala',
                      path: '/ticketing',
                    })
                  }
                }
              })
            }

            const path = ability.find(pathItem => pathItem.path !== undefined)
            if (path !== undefined) Object.assign(data, { path: path.path })

            data.ability = ability
            this.$ability.update(ability)

            localStorage.setItem('abilitosss', JSON.stringify(ability))

            secureLs.setItem('userData', JSON.stringify(data))
            store.commit('auth/UPDATE_USER_DATA', data)

            this.$router.go(getHomeRouteForLoggedInUser(role))
          }
        },
        false,
      )
    })
  },
  methods: {
    loginKomform() {
      return new Promise((resolve, reject) => {
        window.addEventListener(
          'message',
          event => {
            if (event.data.type === 'komform-signin-complete') {
              resolve('OK')
            }
            if (event.data.type === 'komform-signin-error') {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('ERROR')
            }
          },
          false,
        )
        const iframe = document.createElement('iframe')
        iframe.src = `${process.env.VUE_APP_BASE_URL_KOMFORM}/auth/programmatic-signin?email=${this.email}&password=${this.password}`
        iframe.width = '0'
        iframe.height = '0'
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
      })
    },
  },
}
</script>
